import {initHeaderScrollShadow} from "../../../js/util/HeaderUtils.js";

export default class HomePageHeaderHtml {
    #root;

    init(headerEl) {
        this.#root = headerEl;
        initHeaderScrollShadow(headerEl);
    }

    /** @returns {HTMLElement} */
    getUserHeaderElement() {
        return this.#root.querySelector('.page-header__section--user');
    }
}