export default class VisualizationsSorter{

    /**
     * @param {VisualizationProperties[]} visualizations
     * @returns {(*&{location: {col: *, row: *}})[]}
     */
    static sortByLocation(visualizations) {
        const parsedLocations = [];
        const rowTracker = new Map();

        // Parse location from string to {row, col} and separate visualizations by row index
        visualizations.forEach((vis) => {
            const str = vis.card.location ? vis.card.location.split(':').map(Number) : [];
            if (str.length === 2 && !isNaN(str[0]) && !isNaN(str[1]) && str[0] >=0 && str[1] >= 0) {
                const row = str[0];
                const col = str[1];
                if (!rowTracker.has(row)) {
                    rowTracker.set(row, []);
                }
                rowTracker.get(row).push({ obj: vis, row, col});
            } else {
                // If some location can't be parsed, add this visualization to a separate array
                if (!rowTracker.has('invalid')) {
                    rowTracker.set('invalid', []);
                }
                rowTracker.get('invalid').push({obj: vis, row: 'invalid', col: null});
            }
        });

        let newRowIdx = Math.max(...[...rowTracker.keys()].filter(key => key !== 'invalid').map(Number)) + 1;

        // Check duplicated cols index inside of row
        rowTracker.forEach((items, row) => {
            if (row === 'invalid') { // with invalid location
                let currentCol = 0;
                items.forEach((item, idx) => {
                    if (idx > 4) { // max 4 elements in the row
                        newRowIdx++;
                        currentCol = 0;
                    }
                    parsedLocations.push({ obj: item.obj, row: newRowIdx, col: currentCol++ });
                });
            } else { // with regular location
                items.sort((a, b) => a.col - b.col);

                // set new indexes
                items.forEach((item, idx) => {
                    parsedLocations.push({ obj: item.obj, col: idx, row: item.row });
                });
            }
        });

        return parsedLocations.map(({ row, col, obj }) => {
            obj.card.location = row.toString() + ":" + col.toString();
            return obj
        });
    }
}