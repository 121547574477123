export default class TimeUtils {
    /**
     * Returns whether the date is a current day
     * @returns {boolean}
     */
    static isToday(date) {
        const today = new Date();
        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
    }
    /**
     * Returns the current UTC date (without time) as a Date object.
     * @returns {Date} - A Date object set to the start of the current UTC day.
     */
    static utcNowDate() {
        const now = new Date();
        return new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
    }
    /**
     * Adds a specified number of days to a given date.
     * @param {Date} date - The date to add days to.
     * @param {number} days - The number of days to add.
     * @returns {Date} - A new Date object with the added days.
     */
    static addDays(date, days) {
        TimeUtils.assertDate(date);
        if (typeof days !== 'number')
            throw new TypeError('Invalid argument (days): expected a number.');
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }
    /**
     * Converts a Date object to an ISO string.
     * @param {Date} date - The date to convert.
     * @returns {string} - The ISO string representation of the date.
     */
    static toIsoString(date) {
        TimeUtils.assertDate(date);
        return date.toISOString();
    }
    /**
     * Converts a Date object to an ISO string.
     * @param {Date} date - The date to convert.
     * @returns {string} - The ISO string representation of the date.
     */
    static toIsoDateString(date) {
        TimeUtils.assertDate(date);
        return TimeUtils.toIsoString(date).split('T')[0];
    }

    /**
     * @param {Date} date - The date to convert.
     * @return {number} - The epoch seconds
     */
    static toEpoch(date) {
        TimeUtils.assertDate(date);
        return Math.floor(date.getTime() / 1000);
    }

    /**
     * Converts an ISO string to the Date object.
     * @param date
     * @return {Date|null}
     */
    static parseDateTimeStringOrNull(date) {
        if (date == null)
            return null;
        return new Date(date);
    }

    static assertDate(date) {
        if (!(date instanceof Date))
            throw new TypeError('Invalid argument: expected a Date object.');
    }
}