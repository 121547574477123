import Range from "./Range.js";

export default class PeakJson {
  constructor(id, substanceId, chromatogramId, indexRange, rtIdx, area, areaPercent, rt, signalId) {
    this._id = id;
    this._substanceId = substanceId;
    this._chromatogramId = chromatogramId;
    this._indexRange = indexRange;
    this._rtIdx = rtIdx;
    this._area = area;
    this._percentOfTic = areaPercent;
    this._rt = rt;
    this._signalId = signalId;
  }

  /** @return {string} */
  getId(){return this._id}
  /** @return {string} */
  getSubstanceId(){return this._substanceId}
  /** @return {string} */
  getChromatogramId(){return this._chromatogramId}
  /** @return {Range} */
  getIndexRange() {return this._indexRange}
  setIndexRange(range) {
    this._indexRange = range;
  }
  /** @return {number} */
  getArea() {return this._area}
  /** @return {number} */
  getPercentOfTic() {return this._percentOfTic}
  /** @return {number} */
  getRt() {return this._rt}
  /** @return {number} */
  getRtIdx(){return this._rtIdx}
  /** @return {string} */
  getSignalId(){return this._signalId}
  /** @return {boolean} */
  isEmpty(){return !this._range || this._range.isEmpty() }

  toServerJsonString(){
    const peak = {
      area: this.getArea(),
      chromatogramId: this.getChromatogramId(),
      id: this.getId(),
      indexRange: this.getIndexRange().asArray(),
      percentOfTic: this.getPercentOfTic(),
      substanceId: this.getSubstanceId(),
      rt:this.getRt(),
      signalId:this.getSignalId()
    }
    return JSON.stringify(peak)
  }

  /**
   * @param json
   * @return {PeakJson}
   */
  static parseServerJson(json){
    return new PeakJson(json.id, json.substanceId, json.chromatogramId,
        new Range(json.indexRange[0],json.indexRange[1]),
        json.rtIdx, json.area, json.areaPercent, json.rt, json.blobs.spectrum
    );
  }
}
